<template>
  <div>
    <template v-if="isRollCallTab">
      <agenda-roll-call-card :record="record"> </agenda-roll-call-card>
    </template>

    <template v-else-if="isClerkModuleTab">
      TODO: display Clerk Module
    </template>

    <template v-else>
      <legislator-notepad-card :record="record"></legislator-notepad-card>
    </template>
  </div>
</template>

<script>
import { agendaMeetingTabOptions } from "@/model/agenda/agendaModel";

export default {
  name: "AgendaTabsContent",
  components: {
    LegislatorNotepadCard: () =>
      import("@/components/shared/core/agenda/LegislatorNotepadCard"),
    AgendaRollCallCard: () =>
      import("@/components/shared/core/agenda/AgendaRollCallCard")
  },
  props: {
    tab: {
      type: Number,
      required: true
    },
    record: undefined
  },

  computed: {
    /**
     * Compute if selected tab is RollCall Tab
     * @return {boolean}
     */
    isRollCallTab() {
      return this.tab === agendaMeetingTabOptions.rollCall;
    },

    /**
     * Compute if selected tab  is ClerkModule Tab
     * @return {boolean}
     */
    isClerkModuleTab() {
      return this.tab === agendaMeetingTabOptions.clerkModule;
    }
  }
};
</script>

<style scoped></style>
